import { useTagsViewStore } from "@/store/modules/tagsView"
import { useUserStore } from "@/store/modules/user"
import { deleteCookie, getCookie } from "./cookie";
import { getTenantJump } from "@/api/login/user";
import { getAllQueryParam, getQueryString } from "./url";
const authTokenKey = 'FUWEEX_ACCESS_TOKEN'
let isConfirm = false;


/**
 * 华创第三方单点登录对接
 */
export const openOssLogin = () => {
  const { modulename, moduleurl } = getAllQueryParam()
  const list = JSON.parse(localStorage.getItem('module-cache-list') || '[]').filter((item) => item.i > Date.now());
  const item = {
    i: Date.now() + 1000 * 600, // 有效期10分钟 （没有新的链接不会失效）
    modulename,
    moduleurl,
  }
  localStorage.setItem('module-cache-list',JSON.stringify([...list, item]))

  fetch(`/admin-api/tenant-sso/sup-login/getAuthorizeUrl?tenant=nauratest&clientName=PC&modelkey=${item.i}`).then((res) => {
      return res.json();
  }).then((data) => {
      window.open(data.data, "_self")
  })
}

export const returnLogin = () => {
    const tagsViewStore = useTagsViewStore()
    const user = useUserStore();
    tagsViewStore.delAllViews()
    user.setToken('')
    user.setUserInfo(undefined)
    user.setRoleRouters([])
    if(isConfirm) return;
    isConfirm = true;
    // 判断是否为第三方单点登录
    if(import.meta.env.VITE_APP_TITLE == 'HUACHUANG'){
      return openOssLogin();
    }
    
    // 逻辑更新，不需要弹窗提示，直接跳转登陆页面
    const tenant = getCookie('tenant')
    const { origin, pathname, hash } = location
    const redirect_uri = encodeURIComponent(origin + pathname + hash)
    deleteCookie(authTokenKey)
    // deleteCookie('SSO_ACCESS_TOKEN')
    if (tenant) {
      return getTenantJump({
        tenant,
        uiLocales: getCookie('language'),
        redirectUri: redirect_uri
      }).then((res) => {
        window.open(`${ res.data }`, '_self')
      }).catch(() => {
        window.open(`${ location.origin }/tenant/index.html?redirect_uri=${ redirect_uri }`, '_self')
      })
    } else {
      window.open(`${ location.origin }/tenant/index.html?redirect_uri=${ redirect_uri }`, '_self')
    }
}