import { createProdMockServer } from 'vite-plugin-mock/es/createProdMockServer'

const modules = import.meta.glob('./**/*.mock.ts', {
  import: 'default',
  eager: true
})

const mockModules: any[] = []
Object.keys(modules).forEach(async (key) => {
  if (key.includes('_')) {
    return
  }
  mockModules.push(...(modules[key] as any))
})

export function setupProdMockServer() {
  createProdMockServer(mockModules)
  // 接口用mock数据
  // createProdMockServer(
  //   mockModules.map((o) => ({
  //     ...o,
  //     url: `${import.meta.env.VITE_API_BASE_PATH}${o.url}`
  //   }))
  // )
}
