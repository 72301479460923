import request from '@/axios'
import type { UserType } from './types'

interface RoleParams {
  roleName: string
}

export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/mock/user/login', data })
}

export const loginOutApi = (data?): Promise<IResponse> => {
  return request.post({
    url: '/system/sso-auth/logout',
    data: {
      source: 1,
      ...data,
    }
  })
}

export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: UserType[]
      total: number
    }
  }>({ url: '/mock/user/list', params })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}

export const getUserTabMenusApi = (params: any) => {
  return request.get({ url: '/mock/user/tabMenu/role', params })
}

export const getApplicationTypeApi = (params: any) => {
  return request.get({ url: '/mock/user/application/type', params })
}

export const getApplicationListApi = (params: any) => {
  return request.get({ url: '/mock/user/application/list', params })
}

// 单点登录 - 获取用户信息（已废弃）
export const SSOAuth = (data: any) => {
  return request.post({ url: '/system/sso-auth/login', data })
}

// 新的登陆接口（code 换凭证）
export const protalLogin = (data: any, headers: any) => {
  return request.post({ url: '/system/sso-auth/portal-login', data, headers })
}

// 登陆（已经有凭证）
export const getLoginUserInfo = () => {
  return request.get({ url: '/system/sso-auth/get-login-user-info' })
}

// 使用租户名，获得租户编号（已废弃）
export const getTenantIdByName = (name: string) => {
  return request.get({ url: '/system/tenant/get-id-by-name?name=' + name })
}


// 获取租户信息（配置）
export const getTenantcodeConfig = (params: any) => {
  return request.get({ url: '/system/tenant/get-id-by-code', params });
}


// 第三方租户登陆
export const tenantJumpToSubDomain = (params: any) => {
  return request.get({ url: `/system/sso-auth/get-tenant-jump-to-sub-domain`, params });
}