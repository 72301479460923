import { getLoginUserInfo, getTenantcodeConfig, loginOutApi, protalLogin, tenantJumpToSubDomain } from '@/api/login'
import { UserLoginType, UserType } from '@/api/login/types'
import { getTenantJump, getUserInfo } from '@/api/login/user'
import { useCache } from '@/hooks/web/useCache'
import { t, useI18n } from '@/hooks/web/useI18n'
import { i18n } from '@/plugins/vueI18n'
import { store } from '@/store'
import { deleteCookie, getCookie, setCookies } from '@/utils/cookie'
import { ElMessageBox } from 'element-plus'
import { defineStore } from 'pinia'
import { useTagsViewStore } from './tagsView'
import { languageLisType } from '@/api/system/language'
import { useAppStore } from './app'
import { useAppModuleStore } from './module'
import { useNotification } from './notification'
import tenantStore from '../tenant'
import { getAllQueryParam, getQueryString } from '@/utils/url'
import { useDictStore } from './dict'
import { returnLogin } from '@/utils/login'
import { useTabsStore } from './tabs'


const { wsCache } = useCache()
const TenantIdKey = 'TENANT_ID'
const TenantNameKey = 'TENANT_NAME'
const authTokenKey = 'FUWEEX_ACCESS_TOKEN'


interface UserState {
  userInfo: UserType
  tokenKey: string
  token: string
  ssoTokenKey: string,
  refreshToken: string,
  ssoAuthToken: string,
  roleRouters?: string[] | AppCustomRouteRecordRaw[]
  rememberMe: boolean
  loginInfo?: UserLoginType
}

const initLanguage = async  () => {
  await languageLisType().then((res) => {
    // @ts-ignore
    i18n.global.messages.value[i18n.global.locale.value] = res?.data;
  })
}

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      userInfo: { userName: '' },
      tokenKey: 'Authorization',
      token: '',
      ssoTokenKey: 'sso-authorization',
      refreshToken: "",
      ssoAuthToken: '',
      roleRouters: undefined,
      // 记住我
      rememberMe: true,
      loginInfo: undefined
    }
  },
  persist: {
    // 只持久化 name 和 preferences
    paths: ['roleRouters', 'rememberMe', 'loginInfo'],
  },
  getters: {
    getTokenKey(): string {
      return this.tokenKey
    },
    getToken(): string {
      return  this.token;
      // return this.token
    },
    getSsoTokenKey(): string {
      return this.ssoTokenKey
    },
    getRefreshToken(): string {
      return this.refreshToken;
    },
    getSsoAuthToken(): string {
      return this.ssoAuthToken
    },
    getUserInfo(): UserType | undefined {
      return this.userInfo
    },
    getRoleRouters(): string[] | AppCustomRouteRecordRaw[] | undefined {
      return this.roleRouters
    },
    getRememberMe(): boolean {
      return this.rememberMe
    },
    getLoginInfo(): UserLoginType | undefined {
      return this.loginInfo
    }
  },
  actions: {
    getAccessToken(){
      return getCookie(authTokenKey) || this.token;
    },
    ActivityMonitoring(){
      let time = 3 * 3600 * 1000;  // 活动监听时长（3小时）

      const notAdiaphoria = () => {
        this.logout();
      }

      let activityTimeout = setTimeout(notAdiaphoria, time); // 页面加载后5秒无活动更新状态
      // 更新状态
      function updateStatus() {
        // console.log("重置活动状态")
        // 重置活动状态
        resetActivity();
      }

      // 重置活动状态
      function resetActivity() {
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(notAdiaphoria, time);
      }

      // 监听用户活动事件
      window.addEventListener('mousemove', updateStatus);
      window.addEventListener('keydown', updateStatus);
      window.addEventListener('mousedown', updateStatus);
      window.addEventListener('mouseup', updateStatus);
      window.addEventListener('touchstart', updateStatus);
      window.addEventListener('touchend', updateStatus);
    },
    async login(){
      const tenant = getCookie('tenant');
      const appStore = useAppStore()

      await tenantJumpToSubDomain({ host: location.host, tenantCode: tenant }).then(async ({ data }) => {
        console.log('data', data)
        if(!data){
          this.reset();
          return Promise.reject("未登录")
        }
        const { code, icon, logo } = data;
        appStore.setLogoUrl(logo);
        if(!tenant || tenant != code){
          // 已登录其他租户，或为次级域名访问不匹配
          setCookies({
            tenant: code,
            tenantIcon: icon,
            tenantLogo: logo,
          })
          this.reset();
          return Promise.reject("未登录")
        }else {
          // 租户信息正常，写入租户信息
          setCookies({
            tenantIcon: icon,
            tenantLogo: logo,
          })
          setTenantId(data.id)
          if(icon) document.getElementById('tenant-icon')?.setAttribute('href', icon);
          tenantStore.setInfo(data);
          setTenantName(tenant || '')
        }
      })

      const moduleStore = useAppModuleStore()
      const notification = useNotification()
      const dictStore = useDictStore();

      const onLogin = async (res) => {
        await Promise.all([
          initLanguage(),
          dictStore.setDictMap(), // 确保dict 加载成功
        ])
        this.setToken(res.data.accessToken)
        this.setSsoAuthToken(res.data.ssoAccessToken)
        this.setRefreshToken(res.data.refreshToken)
        this.setUserInfo(res.data)
        this.getApiUserInfo();
        appStore.setTitle(res.data.tenant)
        setCookies(res.data.customFormCookies)
        moduleStore.setAppList(res.data.applicationRouteList)
        moduleStore.getAllAppModuleApi() // 获取所有模块列表
        notification.initCount() // 获取通知信息

        /**
         * 判断是否存在临时地址
         */
        const { modelkey, modulename, moduleurl } = getAllQueryParam()
        if(modelkey){
          const list = JSON.parse(localStorage.getItem('module-cache-list') || '[]');
          const item = list.find((o) => o.i == modelkey);
          if(item && item.modulename && item.moduleurl){
            const tabls = useTabsStore();
            // 将moduleurl 作为临时路由，在portal 打开
            setTimeout(() => {
              // 延迟1S 等待布局加载
              tabls.openTabMenu({
                moduleName: item.modulename,
                moduleCode: Number(modelkey).toString(32),
                appCode: 'temp',
                isTemp: true,
                routeUrl: item.moduleurl,
              })
            },1000)
          }
        }else if(modulename && moduleurl) {
          const tabls = useTabsStore();
          // 将moduleurl 作为临时路由，在portal 打开
          setTimeout(() => {
            // 延迟1S 等待布局加载
            tabls.openTabMenu({
              moduleName: modulename,
              moduleCode: Date.now().toString(32),
              appCode: 'temp',
              isTemp: true,
              routeUrl: moduleurl,
            })
          },1000)
        }
      }
    
      let code = getQueryString('code');
      if(getCookie(authTokenKey)){
        // 优化逻辑、相同配置一起获取
        await getLoginUserInfo().then(async (res: any) => {    
          await onLogin(res);
        })
    
      }else {
        if(code){
          const {origin, pathname, hash} = location;
          const redirect_uri = origin + pathname + hash;
          // 单点登录
          await protalLogin({
            redirectUri: redirect_uri,
          }, {
            code: getQueryString('code')
          }).then(async (res: any) => {
            if(!res.data){
              await ElMessageBox.confirm(
                t('common.confirmTitle', '系统提示'),
                "当前登录用户不存在",
                {
                  showCancelButton: false,
                  closeOnClickModal: false,
                  showClose: false,
                  confirmButtonText: t('sys.login.reLogin', '重新登录'),
                  type: 'warning'
                }
              ).finally(() => {
                this.reset();
              })
            }else {
              await onLogin(res);
            }
          })
        }else {
          this.reset();
          return;
        }
      }
      // this.ActivityMonitoring();
    },
    setTokenKey(tokenKey: string) {
      this.tokenKey = tokenKey
    },
    setToken(token: string) {
      // setCookies({ [authTokenKey]: token })
      this.token = token
    },
    setSsoTokenKey(tokenKey: string) {
      this.ssoTokenKey = tokenKey
    },
    setSsoAuthToken(token: string) {
      // setCookies({ SSO_ACCESS_TOKEN: token })
      this.ssoAuthToken = token
    },
    setRefreshToken(token: string){
      this.refreshToken = token;
    },
    setUserInfo(userInfo?: UserType, isApiUserInfo = false) {
      this.userInfo = (isApiUserInfo ? { ...this.userInfo, ...userInfo } : userInfo) as any
    },
    setRoleRouters(roleRouters: string[] | AppCustomRouteRecordRaw[]) {
      this.roleRouters = roleRouters
    },
    logoutConfirm() {
      const { t } = useI18n()
      ElMessageBox.confirm(t('common.loginOutMessage'), t('common.reminder'), {
        confirmButtonText: t('common.ok'),
        cancelButtonText: t('common.cancel'),
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const { origin } = location
            const redirect_uri = encodeURIComponent(`${origin}/#/`)
            await loginOutApi({
              redirectUri: redirect_uri,
              uiLocales: getCookie('language')
            }).then((res) => {
              deleteCookie(authTokenKey)
              window.open(res.data, '_self')
            }).catch(() => {
              // 刷新页面
              deleteCookie(authTokenKey)
              location.reload();
            }).finally(() => {
              instance.confirmButtonLoading = false
            })
          }
        }
      })
      .then(async () => {
      })
      .catch(() => { })
    },
    reset() {
      returnLogin()
    },
    logout() {
      this.reset()
    },
    setRememberMe(rememberMe: boolean) {
      this.rememberMe = rememberMe
    },
    setLoginInfo(loginInfo: UserLoginType | undefined) {
      this.loginInfo = loginInfo
    },
    getApiUserInfo() {
      getUserInfo().then((res) => {
        this.setUserInfo(res.data, true)
      })
    }
  },
})

export const useUserStoreWithOut = () => {
  return useUserStore(store)
}


export const setTenantName = (username: string) => {
  wsCache.set(TenantNameKey, username, { exp: 30 * 24 * 60 * 60 })
}

export const getTenantName = () => {
  return wsCache.get(TenantNameKey)
}

export const removeTenantName = () => {
  wsCache.delete(TenantNameKey)
}

export const getTenantId = () => {
  return wsCache.get(TenantIdKey)
}

export const setTenantId = (username: string) => {
  wsCache.set(TenantIdKey, username)
}

export const removeTenantId = () => {
  wsCache.delete(TenantIdKey)
}
