import { defineStore } from 'pinia'
import router from "@/router";
import { useAppStore } from './app';
import { watch } from 'vue';
import { useAppModuleStore } from './module';
import { t } from '@/hooks/web/useI18n';
import tenant from '../tenant';
import { returnLogin } from '@/utils/login';
import { useUserStore } from './user';

const KEY = {
  OPEN_TAB: 'tab.open',
  OPEN_SAME_TAB: 'tab.open.same',
  OPEN_TEMP_TAB: 'tab.open.temp',
  REFRESH_TAB: 'tab.refresh',
  FULLSCREEN: 'window.fullscreen',
  RELOAD: 'reload',
  OPEN_BROWSER_TAB: 'open.browser.tab',
  AUTH_FAIL: 'auth.fail',
  CLOSE: 'close',
  FORWARD: 'forward',
  ALIVE: 'alive',
};


const cacheKey = "tabs-route-cache";
interface TabItem {
  appCode: string;
  moduleCode: string;
  routeUrl?: string;
  layout?: LayoutType;
  name?: string; // 组件名称
  path?: string;
  params?: string;  // url 参数
  moduleName?: string;
  // 是否禁止删除
  noDelete?: boolean;
  // 是否禁止缓存
  noCache?: boolean;
  // 临时路由
  isTemp?: boolean;
}

interface TabsState {
    iframeUrl: string;
    tabMenu: TabItem[];
    currentTab: string;
}

const app = useAppStore();
const module = useAppModuleStore();



const getPath = (tab: TabItem) => {
  if(!tab.path){
    tab.path = `/module/${tab.moduleCode.replace(/[\.\?\=]/g, '/')}`;
  }
  return tab.path;
}

export const useTabsStore = defineStore('tabStore', {
  state: (): TabsState => {
    return {
      iframeUrl: "",
      tabMenu: [],
      currentTab: "",
    }
  },
  getters: {
    getCurrentTab(): string {
        return this.currentTab
    },
    getTabMenu(): any[] {
        return this.tabMenu
    },
    getTabRoutes(){
        return [];
    },
    // 获取需要缓存路由key
    getCacheKeys(): string[]{
      return this.tabMenu.filter((o) => !o.noCache && o.name).map((o) => o.name as string);
    }
  },
  actions: {
    initTabMenu(){
      // 根据权限进行初始化设置
      const { portalHomeSettingFlag, portalWorkbenchSettingFlag } = tenant.info;
      const workRoute: any = {
        noDelete: true,
        appCode: "",
        path: "/portal/index",
        moduleName: t('sys.workspace'),
        name: "PortalIndex",
        layout: "topLeft",
        moduleCode: "PortalIndex",
      }
      const homeRoute: any = {
        noDelete: true,
        appCode: "",
        path: "/portal/home",
        moduleName: t('sys.homepage'),
        name: "GridLayoutView",
        layout: "topLeft",
        moduleCode: "GridLayoutView",
      }
      let result: any[] = [];
      if(portalHomeSettingFlag){
        result.push(homeRoute);
      }
      if(portalWorkbenchSettingFlag){
        result.push(workRoute)
      }
      if(result.length){
        this.setTabMenu([...result, ...this.tabMenu]);
      }
    },
    setCurrentTab(currentTab: string) {
      const item = this.tabMenu.find((o) => o.moduleCode == currentTab);
      // this.currentTab = currentTab
      if(item){
        router.push(getPath(item));
      }
    },
    setTabMenu(tabMenu: any[]) {
      this.tabMenu = tabMenu
      const user = useUserStore();
      sessionStorage.setItem(`${cacheKey}-user`, user.userInfo.userName);  // 记录当前登录用户
      sessionStorage.setItem(cacheKey, JSON.stringify(tabMenu)); // 写入缓存
    },
    getUrl(tab: TabItem){
      const baseUrl = tab.isTemp? '' : module.appNode[tab.appCode].routePrefix;
      return `${baseUrl}${tab.routeUrl}`;
    },
    // 设置iframe 地址
    setIframeUrl(url: string){
      this.iframeUrl = url;
    },
    // 添加路由
    addRoute(tab: TabItem){

      // 添加路由
      router.addRoute("Root", {
        path: getPath(tab),
        name: tab.moduleCode,
        component: () => import('@/views/Application/empty.vue'),
        props: {
          url: this.getUrl(tab)
        }
      });
    },
    // 删除路由
    removeRoute(tab: TabItem){
      router.removeRoute(tab.moduleCode);
    },
    /**
     * 如果已经存在菜单，则打开，反之添加页面后打开
     */
    openTabMenu(tab: TabItem) {
      const item = this.tabMenu.find((o) => o.moduleCode == tab.moduleCode);
      if(!item){
        this.setTabMenu([...this.tabMenu, { ...tab, path: getPath(tab) }])
        this.addRoute(tab);
      }else if(item.isTemp) {
        // 判断是否为临时路由(临时路由，存在则刷新菜单，不重新打开)
        const eTab = { ...tab, path: getPath(tab) };
        if(item.routeUrl != eTab.routeUrl){ // 路由不一致
          // 更新菜单数据
          this.setTabMenu(this.tabMenu.map((o) => {
            if(o.moduleCode == item.moduleCode) return eTab;
            return o;
          }))
          // 刷新iframe
          this.refreshTab(eTab.moduleCode);
        }
      }
      router.push(getPath(tab));
      app.setCollapse(true)
    },
    /**
     * 删除菜单
     */
    deleteTabMenu(tab: TabItem) {
      this.setTabMenu(this.tabMenu.filter((o) => o.moduleCode !== tab.moduleCode));
      if(this.currentTab == tab.moduleCode){
        // 删除标签为当前打开窗口时，切换到最后一个标签页
        this.setCurrentTab(this.tabMenu[this.tabMenu.length - 1].moduleCode);
      }
      // 同时删除路由
      this.removeRoute(tab);
    },
    /**
     * 路由切换
     */
    changeRoutes(path: string) {
      const item = this.tabMenu.find((o) => o.path == path);
      if(item){
        this.currentTab = item.moduleCode;
        // 切换当前布局模式
        // app.setLayout(item.layout || "top");
      }else {
        // 当前不是
        this.currentTab = "";
        // 切换为默认布局模式
        // app.setLayout("topLeft");
      }
      // 设置iframe 地址
      if(item && item.appCode){
        this.setIframeUrl(this.getUrl(item))
      }else {
        this.setIframeUrl("");
      }
    },
    // 刷新tab
    refreshTab(code){
      const model = this.tabMenu.find((o) => o.moduleCode == code); // 匹配成功
      if(model){
        const iframeDom = document.getElementById(model.moduleCode) as HTMLIFrameElement;
        if(iframeDom){
          // 刷新iframe
          let url = this.getUrl(model) as string;
          iframeDom.setAttribute('src', 'about:blank');
          setTimeout(() => {
            let iframeDom2 = document.getElementById(model.moduleCode) as HTMLIFrameElement;
            if(iframeDom2) iframeDom2.setAttribute('src', url);
          },300)
        }
      }
    },
    handleMessage(event){
      const { key, target, extra }: any = event.data;
      let moduleCode;
      switch(key){
        case KEY.OPEN_TAB:
        case KEY.OPEN_SAME_TAB:
          moduleCode = target.split('/').pop().replace(/\-/g, '.');
          if(module.moduleNode[moduleCode]){
            // 匹配到系统设置模块
            this.openTabMenu(module.moduleNode[moduleCode]);
            if(this.tabMenu.find((o) => o.moduleCode == moduleCode)){
              // 存在标签页直接刷新
              this.refreshTab(moduleCode);
            }
          }
        break;
        /**
         * 打开临时窗口
         * */ 
        case KEY.OPEN_TEMP_TAB:
          moduleCode = extra.path.split('/').pop().replace(/\-/g, '.');
          if(module.moduleNode[moduleCode]){
            // 匹配到系统设置模块
            this.openTabMenu(module.moduleNode[moduleCode]);
          }else {
            this.openTabMenu({
              moduleName: extra.name,
              moduleCode,
              appCode: 'temp',
              isTemp: true,
              routeUrl: extra.iframeUrl
            })
          }
        break;
        case KEY.REFRESH_TAB:
          moduleCode = target.split('/').pop().replace(/\-/g, '.');
          this.refreshTab(moduleCode);
        break;
        case KEY.AUTH_FAIL:
          returnLogin();
        break;
        case KEY.CLOSE:
          moduleCode = target.split('/').pop().replace(/\-/g, '.');
          let paths:string[] = target.split('?');
          paths[0] = paths[0].replace(/\./g, '-');
          const closePath = paths.join('?').trim();
          const item = this.tabMenu.find((o) => {
            // 兼容老的protal 逻辑
            return o.moduleCode == moduleCode || (o.routeUrl && o.routeUrl.replace('?','/').endsWith(closePath));
          });
          if(item) {
            this.deleteTabMenu(item);
          }
        break;
      }
    },
    // 初始化路由
    initRoutes(){
      const user = useUserStore();
      const routesStr = sessionStorage.getItem(cacheKey); // 读取缓存
      const userName = sessionStorage.getItem(`${cacheKey}-user`);  // 记录当前登录用户
      if(userName == user.userInfo.userName){
        if(routesStr){
          const path = router.currentRoute.value.path;
          const list = JSON.parse(routesStr);
          // 查找临时路由是否存在
          const item = list.find((o) => o.path == path);
          // 打开模块(如果是临时路由，则不打开)
          if(item){ // && !item.isTemp  先注销
            this.openTabMenu(item as any);
            return true;
          }else {
            router.push("/")
          }
        }else {
          router.push("/")
        }
      }else {
        // 用户名不匹配（直接定位到首页）
        router.push("/")
        return true;
      }
      return true;  // 新需求，隐藏404页面
    }
  }
})

const tabStore = useTabsStore();
// 监听路由变化
watch(() => router.currentRoute.value, () => {
  tabStore.changeRoutes(router.currentRoute.value.path);
})


/**
 * 监听iframe 抛出的事件
 */
window.addEventListener('message', tabStore.handleMessage);