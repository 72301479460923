import request from '@/axios'


// 切换多语言
export const switchLanguageType = (data: any) => {
    
    return request.post({
        url: `/hrm/common-language/switch-language-type`,
        data,
    })
}


// 获取当前多语言信息
export const languageLisType = () => {
    return request.get({
        url: `/hrm/common-language/list-by-language-type`,
    })
}
