import { useAppStore } from './modules/app'
import { useLocaleStore } from './modules/locale'

interface TenantInfo {
  /**
   * portal首页是否开启：true/false
   */
  portalHomeSettingFlag: boolean;

  /**
   * portal工作台是否开启：true/false
   */
  portalWorkbenchSettingFlag: boolean;

  /**
   * portal我的消息是否开启：true/false
   */
  portalMyMessageSettingFlag: boolean;

  /**
   * portal我的待办是否开启：true/false
   */
  portalMyTodoSettingFlag: boolean;

  /**
   * portal报表中心是否开启：true/false
   */
  portalReportCenterSettingFlag: boolean;

  /**
   * portal轮播图片是否开启：true/false
   */
  portalRotatingImagesSettingFlag: boolean;

  /**
   * protal 消息公告内容展示
   */
  portalMyNoticeSettingFlag: boolean;

  /**
   *  protal 个人中心是否展示
   */
  portalPersonalCenterSettingFlag: boolean;

}


// interface TenantConfig {
//     // 主题色配置
//     primaryColor: string;
//     // 布局背景色
//     layoutBgColor: string;
//     // 左侧菜单字体颜色
//     leftMenuTextColor: string;
//     // 头部字体颜色
//     topHeaderTextColor: string;
// }

class Tenant {

  /**
   *
   */
  get noticeIcon() {
    const { portalMyNoticeSettingFlag, portalMyTodoSettingFlag, portalMyMessageSettingFlag } = this.info
    return portalMyMessageSettingFlag || portalMyTodoSettingFlag || portalMyNoticeSettingFlag
  }


  /**
   * 写入租户信息
   */
  setInfo = (info: any) => {
    this.info = info
    const locale = useLocaleStore()
    if (info.languageList) {
      try {
        locale.setLocaleMap(JSON.parse(info.languageList))
      } catch {

      }
    }
    this.setThemeConfig(info.portalLayout, info.themeConfig)
  }

  themeConfig: ThemeTypes = {}


  setThemeConfig = (portalLayout: LayoutType, config: any) => {

    const app = useAppStore()

    app.setLayout(portalLayout)

    if (config) {
      try {
        const data = JSON.parse(config)
        Object.assign(this.themeConfig, data)
      } catch {

      }
    }
    if (localStorage.getItem('elColorPrimary')) {
      // 主题色可以修改，走缓存获取
      this.themeConfig.elColorPrimary = localStorage.getItem('elColorPrimary') as string
    }
    app.setTheme(this.themeConfig) // 设置主题色
    app.setCssVarTheme()   // 将主题色写入到变量
    app.changeGaiaTheme();
  }

  info: TenantInfo = {
    portalPersonalCenterSettingFlag: false,
    portalHomeSettingFlag: false,
    portalWorkbenchSettingFlag: false,
    portalMyMessageSettingFlag: false,
    portalMyTodoSettingFlag: false,
    portalReportCenterSettingFlag: false,
    portalRotatingImagesSettingFlag: false,
    portalMyNoticeSettingFlag: false
  }

}


export default new Tenant()
