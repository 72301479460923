import request from '@/axios'

// 1. 应用列表
export const getAllAppModuleApi = () => {
  return request.get({ url: '/sys/application/get-all-app-module' }).then((res) => {
    res.data = res.data.map((o) => {
      return {
        ...o,
        moduleList: (o.moduleList || []).filter((item) => item.authority == 1)
      }
    })
    return res;
  })
}

// 2. 获取应用角标信息
export const moduleGetSuperscript = (data?) => {
  return request.post({ url: `/tenant-admin/module/get-superscript`, data });
}

// 2. 个人模板
export const getWorkspaceTemplateApi = (params) => {
  return request.get({ url: '/portal/workspace-template-personal/get-template', params }).then((res) => {
    res.data = res.data.map((o) => {
      return {
        ...o,
        moduleList: (o.moduleList || []).filter((item) => item.authority == 1)
      }
    })
    return res;
  })
}

// 3. 保存模板
export const saveWorkspaceTemplateApi = (data: any) => {
  return request.post({ url: '/portal/workspace-template-personal/save-template', data })
}

// 获取轮播图片
export const findRotatingPicture = (data?: any) => {
  return request.get({ url: '/portal/rotating-picture/list', data })
}

// 获取个人模版
export const portalPersonMessage = (params?) => {
  return request.get({ url: `/portal/custom-portal/personMessage`, params })
}

// 获取个人配置模版 /portal/user-portal/get  source = 1 pc  source = 2 mobile
export const getUserProtalGet = () => {
  return request.get({ url: `/portal/user-portal/get`, params: { source: 1 } })
}

// 修改个人首页模版
export const getUserPortalUpdate = (data) => {
  return request.post({ url: `/portal/user-portal/update`, data })
}

// 重置
export const deleteResetPortal = (params) => {
  return request.delete({ url: `/portal/user-portal/revert?source=${params.source}` })
}
