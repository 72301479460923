import request from '@/axios'


/**
 * 获取用户信息
 */
export const getUserInfo = (params?) => {
    return request.get({ url: '/portal/user/user-info', params })
}

/**
 * 获取用户信息（带权限）
 */
export const getSearchUser = (params?) => {
    return request.get({ url: `/hrm/permission-user/searchUser`, params });
}

/**
 * 更新用户信息
 */
export const updateUserInfo = (data?) => {
    return request.put({ url: `/portal/user/update-user-info`, data })
}



/**
 * 获取租户登陆页面
 */
export const getTenantJump = (params) => {
    return request.get({ url: `/system/sso-auth/tenant-jump`, params })
}


/**
 * 切换用户信息
 */
export const getSwitchUser = (data) => {
    return request.post({ url: `/system/sso-auth/switch-user`, data});
}