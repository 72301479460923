import { defineStore } from 'pinia'
import { store } from '../index'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import deDe from 'element-plus/es/locale/lang/de';
import koKR from 'element-plus/es/locale/lang/ko';
import thTh from 'element-plus/es/locale/lang/th';
import frFR from 'element-plus/es/locale/lang/fr';
import jaJR from 'element-plus/es/locale/lang/ja';
import viVN from 'element-plus/es/locale/lang/vi';
import { useStorage } from '@/hooks/web/useStorage'
import { LocaleDropdownType } from '@/components/LocaleDropdown'
import { getCookie } from '@/utils/cookie'
// import { switchLanguageType } from '@/api/system/language'


const elLocaleMap:Record<LocaleType, any> = {
  'ZH-CN': zhCn,
  "EN-US": en,
  'ZH-HK': zhTw,
  'ZH-TW': zhTw,
  'DE-DE': deDe,
  'KO-KR': koKR,
  'TH-TH': thTh,
  'FR-FR': frFR,
  'JA-JP': jaJR,
  'VI-VN': viVN,
  'MS-MY': en,
}

const languages: LocaleDropdownType[] = [
  {
    lang: 'ZH-CN',
    name: '简体中文'
  },
  {
    lang: 'EN-US',
    name: 'English'
  },
  {
    lang: "ZH-HK",
    name: "繁体（香港）"
  },
  {
    lang: "ZH-TW",
    name: "繁体（台湾）"
  },
  {
    name: '德文',
    lang: 'DE-DE',
  },
  {
    name: '韩文',
    lang: 'KO-KR',
  },
  {
    name: '泰文',
    lang: 'TH-TH',
  },
  {
    name: '法文',
    lang: 'FR-FR',
  },
  {
    name: '日文',
    lang: 'JA-JP',
  },
  {
    name: '越南文',
    lang: 'VI-VN',
  },
  {
    name: '马来西亚文',
    lang: 'MS-MY',
  }
]

interface LocaleState {
  currentLocale: LocaleDropdownType
  localeMap: LocaleDropdownType[]
}

export const useLocaleStore = defineStore('locales', {
  state: (): LocaleState => {
    return {
      currentLocale: {
        lang: getCookie('language') || 'ZH-CN' as any,
        elLocale: elLocaleMap[getCookie('language') || 'ZH-CN']
      },
      // 多语言
      localeMap: languages.slice(0,4)
    }
  },
  getters: {
    getCurrentLocale(): LocaleDropdownType {
      return this.currentLocale
    },
    getLocaleMap(): LocaleDropdownType[] {
      return this.localeMap
    }
  },
  actions: {
    setLocaleMap(langKeys: string[]) {
      this.localeMap = languages.filter((o) => langKeys.includes(o.lang));
    },
    setCurrentLocale(localeMap: LocaleDropdownType) {
      // this.locale = Object.assign(this.locale, localeMap)
      this.currentLocale.lang = localeMap?.lang
      this.currentLocale.elLocale = elLocaleMap[localeMap?.lang]
      
      // switchLanguageType({ languageType: localeMap.lang })
    }
  }
})

export const useLocaleStoreWithOut = () => {
  return useLocaleStore(store)
}
