import { SUCCESS_CODE } from '@/constants'

const timeout = 1000

const List: {
  username: string
  password: string
  role: string
  roleId: string
  permissions: string | string[]
}[] = [
    {
      username: 'admin',
      password: 'admin',
      role: 'admin',
      roleId: '1',
      permissions: ['*.*.*']
    },
    {
      username: 'test',
      password: 'test',
      role: 'test',
      roleId: '2',
      permissions: ['example:dialog:create', 'example:dialog:delete']
    }
  ]

export default [
  // 列表接口
  {
    url: '/mock/user/list',
    method: 'get',
    response: ({ query }) => {
      const { username, pageIndex, pageSize } = query

      const mockList = List.filter((item) => {
        if (username && item.username.indexOf(username) < 0) return false
        return true
      })
      const pageList = mockList.filter(
        (_, index) => index < pageSize * pageIndex && index >= pageSize * (pageIndex - 1)
      )

      return {
        code: SUCCESS_CODE,
        data: {
          total: mockList.length,
          list: pageList
        }
      }
    }
  },
  // 登录接口
  {
    url: '/mock/user/login',
    method: 'post',
    timeout,
    response: ({ body }) => {
      const data = body
      let hasUser = false
      for (const user of List) {
        if (user.username === data.username && user.password === data.password) {
          hasUser = true
          return {
            code: SUCCESS_CODE,
            data: user
          }
        }
      }
      if (!hasUser) {
        return {
          code: 500,
          message: '账号或密码错误'
        }
      }
    }
  },
  // 退出接口
  {
    url: '/mock/user/loginOut',
    method: 'get',
    timeout,
    response: () => {
      return {
        code: SUCCESS_CODE,
        data: null
      }
    }
  },
  //用户导航菜单权限
  {
    url: '/mock/user/tabMenu/role',
    method: 'get',
    timeout,
    response: () => {
      return {
        code: SUCCESS_CODE,
        data: [
          {
            label: '首页',
            value: 'home',
            router: {
              path: '/dashboard/analysis',
              name: 'Home'
            },
            noDelete: true
          },
          {
            label: '工作台',
            value: 'workbench',
            router: {
              path: '/workbench/index',
              name: 'workbench'
            },
            noDelete: true
          }
        ]
      }
    }
  },
  // 应用类型
  {
    url: '/mock/user/application/type',
    method: 'get',
    timeout,
    response: () => {
      return {
        code: SUCCESS_CODE,
        data: [{
          id: 1,
          name: '人事组织',
          remark: '主管经理或人事专员可对组织架构及人事资料进行维护',
          children: [{
            id: 1,
            type: 1,
            icon: '',
            name: '组织管理',
            value: 'workbench',
            router: {
              path: '',
              name: 'workbench'
            },
            noDelete: true
          }, {
            id: 2,
            type: 1,
            name: '组织信息',
            icon: '',
            value: 'workbench',
            router: {
              path: '/workbench/index',
              name: 'workbench'
            },
            noDelete: true
          }, {
            id: 3,
            type: 1,
            name: '员工档案',
            icon: '',
            value: 'workbench',
            router: {
              path: '/workbench/index',
              name: 'workbench'
            },
            noDelete: true
          }, {
            id: 4,
            type: 1,
            name: '公司',
            value: 'workbench',
            router: {
              path: '/workbench/index',
              name: 'workbench'
            },
            noDelete: true
          }, {
            id: 5,
            type: 1,
            name: '考勤查询',
            icon: '',
          }, {
            id: 6,
            type: 1,
            name: '薪资查询',
            icon: '',
          }]
        }, {
          id: 2,
          name: '公告中心',
          remark: '主管经理或人事专员可对组织发布公告',
          children: [{
            id: 7,
            type: 2,
            name: '公告编辑',
            icon: '',
          }, {
            id: 8,
            type: 2,
            name: '公告查看',
            icon: '',
          }]
        }, {
          id: 3,
          name: '排版管理',
          remark: '经理或主管可对员工进行排版、修改排班、查看员工可用时段',
          children: [{
            id: 9,
            type: 3,
            name: '排班',
            icon: '',
          }, {
            id: 10,
            type: 3,
            name: '合规性',
            icon: '',
          }, {
            id: 11,
            type: 3,
            name: '班次设置',
            icon: '',
          }, {
            id: 12,
            type: 3,
            name: '支援规则',
            icon: '',
          }]
        }, {
          id: 4,
          name: '工作流',
          remark: '进行各类表单申请、查看员工申请的表单',
          children: [{
            id: 13,
            type: 4,
            name: '表单申请',
            icon: '',
          }, {
            id: 14,
            type: 4,
            name: '我的表单',
            icon: '',
          }, {
            id: 15,
            type: 4,
            name: '我的审批',
            icon: '',
          }]
        }]
      }
    }
  },
  // 应用列表
  {
    url: '/mock/user/application/list',
    method: 'get',
    timeout,
    response: ({ query }) => {
      const List = [{
        id: 1,
        type: 1,
        name: '组织管理',
        icon: '',
      }, {
        id: 2,
        type: 1,
        name: '组织信息',
        icon: '',
      }, {
        id: 3,
        type: 1,
        name: '员工档案',
        icon: '',
      }, {
        id: 4,
        type: 1,
        name: '公司',
        icon: '',
      }, {
        id: 5,
        type: 1,
        name: '考勤查询',
        icon: '',
      }, {
        id: 6,
        type: 1,
        name: '薪资查询',
        icon: '',
      }, {
        id: 7,
        type: 2,
        name: '公告编辑',
        icon: '',
      }, {
        id: 8,
        type: 2,
        name: '公告查看',
        icon: '',
      }, {
        id: 9,
        type: 3,
        name: '排班',
        icon: '',
      }, {
        id: 10,
        type: 3,
        name: '合规性',
        icon: '',
      }, {
        id: 11,
        type: 3,
        name: '班次设置',
        icon: '',
      }, {
        id: 12,
        type: 3,
        name: '支援规则',
        icon: '',
      }, {
        id: 13,
        type: 4,
        name: '表单申请',
        icon: '',
      }, {
        id: 14,
        type: 4,
        name: '我的表单',
        icon: '',
      }, {
        id: 15,
        type: 4,
        name: '我的审批',
        icon: '',
      }]
      const { typeId } = query
      const mockList = List.filter(item => {
        return item.type === typeId
      })

      return {
        code: SUCCESS_CODE,
        data: mockList
      }
    }
  },
]
